import moment from 'moment';
import {BaseErrorCodes} from 'public-shared/models/error/base-error-codes';
import {v4 as UUID} from 'uuid';

export class ErrorItem {
  constructor(
    public parameter: string,
    public message: string
  ) {}
}
/**
 * General class for describing errors.
 */
export class AuthErrorDescriptor {
  /**
   * The error's identification string.
   */
  public id: string;

  constructor(
    public error: string | undefined,
    public errorCode: string | undefined,
    public status: number | undefined,
    public errors: Array<ErrorItem> = [],
    public timestamp: number = +moment().format('x'),
    public url?: string
  ) {
    this.id = UUID();
  }

  /**
   * Generates unknown error with a default status.
   * @param error The input error that could not be processed.
   * @returns {ErrorDescriptor} A default unknown ErrorDescriptor object.
   */
  public static unknownError(error: any): AuthErrorDescriptor {
    return new AuthErrorDescriptor(`Unknown error: ${error.toString()}`, BaseErrorCodes.UNKNOWN_ERROR_IN_FACADE.toString(), 0);
  }

  /**
   * Returns if error code TOKEN_INVALID
   * @returns {boolean}
   */
  public get isTokenInvalid(): boolean {
    return this.errorCode === BaseErrorCodes.TOKEN_INVALID;
  }

  /**
   * Returns error as string
   * @returns {string}
   */
  public toString(): string {
    const error = `${this.error}, ErrorCode: ${this.errorCode}, Status: ${this.status}, Timestamp: ${this.timestamp}`;
    return this.url ? `${error}, URL: ${this.url}` : error;
  }
}
