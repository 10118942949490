import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ButtonSizes, ButtonTypes, ButtonVariants} from '@jtm/jtm-components';
import {CodeLabel} from 'public-shared/models/code-label/code-label.dto';

/**
 * The abstract parent of the login components.
 */
@Component({
  template: ''
})
export abstract class AbstractLoginComponent implements OnInit {
  public readonly buttonSizes = ButtonSizes;
  public readonly buttonVariants = ButtonVariants;
  public readonly buttonTypes = ButtonTypes;
  /**
   * The URL that the user will be navigated after entering the credentials.
   */
  protected returnUrl: string | undefined;
  /**
   * The e-mail address entered by the user.
   */
  public email: string | undefined;

  /**
   * The Auth0 logout url
   */
  public logoutUrl?: string;

  /**
   * List of the tenants
   */
  public tenants: CodeLabel[] | null = null;
  /**
   * Selected merchant object
   */
  public selectedTenant: any = {};
  /**
   * If waiting for server response
   */
  public isBusy: boolean = false;
  /**
   * Form group
   */
  public form: UntypedFormGroup = this._formBuilder.group({
    selectedTenant: new UntypedFormControl('', [Validators.required])
  });

  /**
   * Inject services
   *
   * @param router
   * @param route
   * @param _formBuilder
   * @protected
   */
  protected constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected _formBuilder: UntypedFormBuilder
  ) {}

  /**
   * Initializes the component: subscribes for the return URL parameter from the route.
   * If the user is already logged in, redirects him/her to the return URL parameter.
   */
  ngOnInit(): void {
    const code = this.route.snapshot.queryParams.code;
    const baseUrl = !!code ? `/?code=${code}` : '/';
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || baseUrl;
    this.navigateToReturnUrl();
  }

  /**
   * Set selectedMerchant as an optional field
   */
  public resetSelectedTenantValidators(): void {
    this.form?.get('selectedTenant')?.setValue('');
    this.form?.get('selectedTenant')?.setValidators(null);
    this.form?.get('selectedTenant')?.updateValueAndValidity();
  }

  /**
   * Determine and retrieves form state
   */
  public formIsInvalid(): boolean {
    return !this.form?.valid;
  }

  /**
   * Reset form
   */
  public resetForm(): void {
    this.form?.reset();
  }

  /**
   * Fetch tenants, it must be implemented by the child
   */
  public abstract callMapping(): void;

  /**
   * Api response mapping for tenants, it must be implemented by the child
   */
  public abstract onOptionSelected(option: CodeLabel): void;

  /**
   * Navigate to Auth0 login page
   */
  public abstract directToAuth0Login(): void;

  /**
   * LRedirect User to Login Page OR Logout and redirect
   */
  public abstract goToLoginPage(): void;

  /**
   * Navigates the user to the specified return url.
   */
  protected navigateToReturnUrl(): void {
    //@ts-ignore
    this.router.navigateByUrl(this.returnUrl).then();
  }
}
